import { useSearchEventTicketChart } from "hooks/useSearchEventTicketChart";

import ReactApexChart from "react-apexcharts";

import { LoadingOutlined } from "@ant-design/icons";

import { Colors } from "constants/colors";

import {
  Container,
  CardGraph,
  ContainerGraph,
  ContainerTitle,
  GridGraphs,
  TitleGraph,
  ContainerLoading,
  EmptyStateContainer,
} from "../../../../Financial/components/Tabs/General/Graphs/styles";
import { Spin } from "antd";

export function Graphs() {
  const { data: dataEventTicketChart } = useSearchEventTicketChart({});

  const seriesMostSold =
    dataEventTicketChart?.mostPaidEventTickets.map(
      (ticket) => ticket.purchase_count
    ) ?? [];

  const labelsMostSold =
    dataEventTicketChart?.mostPaidEventTickets.map((ticket) =>
      ticket.event_ticket_name?.substring(0, 12)
    ) ?? [];

  const optionsMostSold: any = {
    chart: {
      width: 580,
      type: "donut",
    },
    labels: labelsMostSold,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
            },
            value: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            show: true,
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      offsetY: 0,
    },
  };

  const ticketMapMostPaid =
    dataEventTicketChart?.mostPaidEventTickets.reduce((acc, ticket) => {
      acc[ticket.event_ticket_name] =
        (acc[ticket.event_ticket_name] || 0) + ticket.purchase_count;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesMostPaid = Object.keys(ticketMapMostPaid).map((name) =>
    name.length > 10 ? name.substring(0, 6) + "..." : name
  );

  const seriesMostPaid = [{ data: Object.values(ticketMapMostPaid) }];

  const optionsMostPaid: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#FDF760", "#66588A", "#DF63BD"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesMostPaid,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "78%",
            height: 250,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const ticketMapMostPending =
    dataEventTicketChart?.mostPendingEventTickets.reduce((acc, ticket) => {
      acc[ticket.event_ticket_name] =
        (acc[ticket.event_ticket_name] || 0) + ticket.purchase_count;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesMostPending = Object.keys(ticketMapMostPending).map((name) =>
    name.length > 10 ? name.substring(0, 12) + "..." : name
  );
  const seriesMostPending = [{ data: Object.values(ticketMapMostPending) }];

  const optionsMostPending: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#FDF760", "#66588A", "#DF63BD"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesMostPending,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "78%",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const ticketMapMostRefund =
    dataEventTicketChart?.mostRefundEventTickets.reduce((acc, ticket) => {
      acc[ticket.event_ticket_name] =
        (acc[ticket.event_ticket_name] || 0) + ticket.purchase_count;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesMostRefund = Object.keys(ticketMapMostRefund).map((name) =>
    name.length > 10 ? name.substring(0, 12) + "..." : name
  );
  const seriesMostRefund = [{ data: Object.values(ticketMapMostRefund) }];

  const optionsMostRefund: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#FDF760", "#66588A", "#DF63BD"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesMostRefund,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "78%",
            height: 250,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 42, color: Colors.primary100 }} spin />
  );

  return (
    <Container>
      <GridGraphs>
        <ContainerGraph>
          <ContainerTitle>
            <TitleGraph>Ingressos mais vendidos</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataEventTicketChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPaidEventTickets?.length > 0 ? (
              <div className="graph">
                <ReactApexChart
                  options={optionsMostSold}
                  series={seriesMostSold}
                  type="donut"
                  width={520}
                />
              </div>
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPaidEventTickets?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <TitleGraph>Ingressos com maior aprovação de Compra</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataEventTicketChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPaidEventTickets?.length > 0 ? (
              <ReactApexChart
                options={optionsMostPaid}
                series={seriesMostPaid}
                type="bar"
                height={220}
              />
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPaidEventTickets?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <TitleGraph>Ingressos com maior reprovação de Compra</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataEventTicketChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPendingEventTickets?.length > 0 ? (
              <ReactApexChart
                options={optionsMostPending}
                series={seriesMostPending}
                type="bar"
                height={220}
              />
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostPendingEventTickets?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <TitleGraph>Ingressos com maior reembolso de Compra</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataEventTicketChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostRefundEventTickets?.length > 0 ? (
              <ReactApexChart
                options={optionsMostRefund}
                series={seriesMostRefund}
                type="bar"
                height={220}
              />
            ) : null}

            {dataEventTicketChart &&
            dataEventTicketChart?.mostRefundEventTickets?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>
      </GridGraphs>
    </Container>
  );
}
